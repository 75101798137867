import React from "react"
import HomeNav from "../HomeNav/HomeNav"
import "./HeaderHomeContent.scss"

const HeaderHomeContent = ({ language }) => (
  <div className="container-fluid homeContent">
    <div className="row">
      <div className="col-md-12">
        <h1>
          <span className="color1">CSS</span> GENERATOR
        </h1>

        {language == "en" ? (
          <p>
            A simple generator that will help you choose the right values for
            selected CSS properties. Easy parameter adjustment with sliders,
            live preview and instant copying of the ready to use CSS code.
          </p>
        ) : (
          <p>
            Prosty generator, za pomocą którego dobierzesz odpowiednie wartości
            dla wybranych właściwości CSS. Wygodne dostosowanie parametrów za
            pomocą suwaków, podgląd na żywo i błyskawiczne kopiowanie gotowego
            kodu CSS.
          </p>
        )}
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <HomeNav language={language} />
      </div>
    </div>
  </div>
)

export default HeaderHomeContent
