import React from "react"
import Seo from "../../components/Seo/Seo"
import HeaderHome from "../../components/HeaderHome/HeaderHome"
import Footer from "../../components/Footer/Footer"

const IndexPage = ({ location }) => {
  const language = location.pathname.substr(1, 2)
  return (
    <>
      <Seo
        title="CSS GENERATOR | Cascading Style Sheets"
        description="CSS GENERATOR | Easy to use tool to generate ready CSS rules. Adjust values with live preview and copy the ready code. Check it now!"
        location={location}
        language={language}
      />
      <HeaderHome language={language} />
      <Footer language={language} />
    </>
  )
}

export default IndexPage
