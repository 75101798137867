import React from "react"
import "./HeaderHome.scss"
import NavTop from "../NavTop/NavTop"
import HeaderHomeContent from "./HeaderHomeContent/HeaderHomeContent"

const HeaderHome = ({ language }) => (
  <header className="headerHome">
    <NavTop notGenerator={true} language={language} />
    <HeaderHomeContent language={language} />
  </header>
)

export default HeaderHome
