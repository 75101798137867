import React from "react"
import HomeNavItem from "./HomeNavItem/HomeNavItem"
import importPages from "../../../data/pages"
import "./HomeNav.scss"

const HomeNav = ({ language }) => {
  const pages =
    language == "en"
      ? importPages.map(item => ({
          ...item,
          url: `/en${item.url}`,
        }))
      : importPages
  return (
    <nav className="homeNav">
      <ul>
        {pages.map(item => (
          <HomeNavItem key={item.name} {...item} />
        ))}
      </ul>
    </nav>
  )
}

export default HomeNav
